/* You can add global styles to this file, and also import other style files */
@import './assets/sass/variables.scss';

@font-face {
  font-family: "Open Sans";
  src: url("./assets/sass/fonts/OpenSans/OpenSans-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "CIBFontSans Bold";
  src: url("./assets/sass/fonts/CIBFontSans/CIBFontSans-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
    font-family: "CIBFontSans";
    src: url("./assets/sass/fonts/CIBFontSans/CIBFontSans-Light.ttf")
      format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?7x6wtc');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?7x6wtc#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?7x6wtc') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?7x6wtc') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?7x6wtc##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
    margin: 0;
    background-color: #FFF;
    * {
      font-family: "Open Sans", sans-serif;
    }
    .small, .xsmall {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: normal;
        color: $general;
    }

    .containter{
      margin: 10px;
    }
    .pointer{
      cursor: pointer;
    }
    .small {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.2px;
    }
    
    .xsmall {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.17px;
    }
    
    h5, h6 {
        font-family: $font-CIBFontSans-Bold !important;
        color: $general;
        margin: 0;
    }

    .h5-light {
        font-family: $font-CIBFontSans;
        color: $general;
        margin: 0;
    }
    
    h5, .h5-light {
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.38px;
    }

    h6 {
        font-size: 18px;
        line-height: 20px;
        letter-spacing: -0.3px;
    }

    .text-center {
        text-align: center;
    }
    
      /* Icons */
    .icon-arrow2-right {
        &:before {
            content: $icon-arrow2-right;
        }
    }
    .icon-cash-contribution {
        &:before {
            content: $icon-cash-contribution;
        }
    }
    .icon-home {
        &:before {
            content: $icon-home;
        }
    }

  .icon-card {
    &:before {
        content: $icon-card;
    }
  }
  .icon-error {
    &:before {
        content: $icon-error;
    }
  }
}

.primary-yellow {
    border: 1px solid $primary-yellow !important;
    background-color: $primary-yellow !important;
    filter: drop-shadow(0px 1px 8px rgba(0, 0, 0, 0.1));

    &:hover {
      border: 1px solid $background-gray2 !important;
      background-color: $primary-yellow-hover !important;
    }

    &:active {
      background-color: $primary-yellow-clicked !important;
      border: 1px solid $primary-yellow-clicked !important;
    }

    &:disabled {
      cursor: no-drop;
      background-color: $background-gray2 !important;
      border: 1px solid $background-gray2 !important;

      .p-button-label {
        opacity: 0.6;
      }
    }

  }

.icon-xl{
    font-size: 32px;
    font-weight: bold;
    line-height: 18px;
    letter-spacing: -0.2px;
}

.pdf-simulation {
  width: 1200px; /* Ajusta según el tamaño deseado */
  height: auto;
  transform: scale(1); /* Opcional: Escalar para asegurar el contenido */
  transform-origin: top left; /* Asegura que la escala se aplique desde la esquina superior izquierda */
}
.success {
  background-color: $success-color;
}
.smallText {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.17px;
}
.midText {
  font-size: 14px;
}
.me-large{
  margin-right: 5rem;
}
.icon-mid-size{
  font-size: xx-large;
}