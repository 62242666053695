/*GENERAL COLORS*/

/** Fonts **/
$font-CIBFontSans: 'CIBFontSans';
$font-CIBFontSans-Bold: 'CIBFontSans Bold';

/** Primary **/
$primary-yellow: #FDDA24;
$primary-yellow-hover: #FDC82A;
$primary-yellow-clicked: #FEE97C;
$color_line: #ABA59D;
$color_error: #E20201;
$gray_color: #F2F2F4;
$border_error: #f0330d;
$color_white: #F9F9FA;

$general: #2C2A29;
$white_color: #E7E7E7;
$whiteD9: #D9DADD;
$white_color2: #FEFAD3;
$brown_color: #454648;



/** Secondary **/


/** Others **/
$white: #ffffff;
$black: #000000;
$general: #2C2A29;
$general-2: #292929;
$background-gray1: #F4F4F4;
$background-gray2: #CCCCCC;
$step-color: #00C389;
$success-color: $step-color;
$success-color-2: #03814A;
$error-color: #FF7F41;
$warning-color: $primary-yellow;
$info-color: #59CBE8;
$gray1: #C4C4C4;
$error-message: #E20201;
$credit-color: #03814A;
$debit-color: $error-message;
$frozen-separator-color: rgba(93, 93, 93, 0.104965);
$messages-gray: #2C2A29CC;

/** icon fonts **/
$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "./assets/sass/fonts/icomoon/" !default;

$icon-send-message: "\ea6f";
$icon-send: "\ea70";
$icon-asterisk: "\2022";
$icon-security: "\e9f1";
$icon-shield-user: "\ea6e";
$icon-my-products: "\ea6c";
$icon-scooter: "\ea6d";
$icon-game-controller: "\ea6b";
$icon-map: "\ea69";
$icon-coding: "\ea68";
$icon-balance: "\ea67";
$icon-marketplace: "\ea3d";
$icon-power-cord: "\ea41";
$icon-project: "\ea45";
$icon-reply-all: "\ea71";
$icon-forward: "\ea47";
$icon-reply: "\ea49";
$icon-token: "\ea4b";
$icon-preparer-User: "\ea43";
$icon-direct-user: "\ea4d";
$icon-approving-user: "\ea4f";
$icon-user-check: "\ea51";
$icon-user-error: "\ea53";
$icon-add-user: "\e900";
$icon-main-users: "\ea55";
$icon-walk: "\ea21";
$icon-achievement: "\ea66";
$icon-people: "\e9cf";
$icon-lend: "\ea57";
$icon-gasoline-tank: "\ea59";
$icon-bank: "\ea5b";
$icon-attachment: "\ea5d";
$icon-affinity: "\ea5f";
$icon-add-store: "\ea61";
$icon-store: "\ea07";
$icon-pending-store: "\ea3f";
$icon-add_square: "\ea63";
$icon-square: "\ea03";
$icon-whatsapp-outline: "\ea2f";
$icon-facebook-outline: "\ea33";
$icon-instagram-outline: "\ea35";
$icon-linkedin-outline: "\ea37";
$icon-twitter-outline: "\ea39";
$icon-youtube-outline: "\ea3b";
$icon-pay-with-wallet: "\e9ce";
$icon-microphone: "\ea31";
$icon-day-to-day: "\e95e";
$icon-coin: "\e955";
$icon-table-settings: "\ea2d";
$icon-megaphone: "\ea29";
$icon-baby-bottle: "\ea2b";
$icon-logos-Bam-endoso-primario-positivo: "\ea2a";
$icon-logos-Bam-endoso-secundario-positivo: "\ea2c";
$icon-logos-Bam-primario-positivo: "\ea2e";
$icon-logos-Bam-secundario-positivo: "\ea30";
$icon-logos-BancadeInversion-primario-positivo: "\ea32";
$icon-logos-BancadeInversion-secundario-positivo: "\ea34";
$icon-logos-BancoAgricola-endoso-primario-positivo: "\ea36";
$icon-logos-BancoAgricola-endoso-secundario-positivo: "\ea38";
$icon-logos-BancoAgricola-primario-positivo: "\ea3a";
$icon-logos-BancoAgricola-secundario-positivo: "\ea3c";
$icon-logos-Bancolombia-endoso-primario-positivo: "\ea3e";
$icon-logos-Bancolombia-endoso-secundario-positivo: "\ea40";
$icon-logos-Bancolombia-filiales-cayman-primario-positivo: "\ea42";
$icon-logos-Bancolombia-filiales-cayman-secundario-positivo: "\ea44";
$icon-bancolomba-panama-horizontal: "\ea46";
$icon-logos-Bancolombia-filiales-panama-secundario-positivo: "\ea48";
$icon-logos-Bancolombia-filiales-puertorico-primario-positivo: "\ea4a";
$icon-logos-Bancolombia-filiales-puertorico-secundario-positivo: "\ea4c";
$icon-logos-Bancolombia-isotipo-negro: "\ea4e";
$icon-logos-Bancolombia-primario-positivo: "\ea50";
$icon-logos-Bancolombia-secundario-positivo: "\ea52";
$icon-logos-Banitsmo-endoso-primario-positivo: "\ea54";
$icon-logos-Banitsmo-endoso-secundario-positivo: "\ea56";
$icon-logos-Banitsmo-primario-positivo: "\ea58";
$icon-logos-Banitsmo-secundario-positivo: "\ea5a";
$icon-logos-FundacionGrupoBancolombia-primario-positivo: "\ea5c";
$icon-logos-FundacionGrupoBancolombia-secundario-positivo: "\ea5e";
$icon-logos-GrupoBancolombia-endoso-positivo: "\ea60";
$icon-logos-GrupoBancolombia-primario-positivo: "\ea62";
$icon-logos-GrupoBancolombia-secundario-positivo: "\ea64";
$icon-logos-vigilado: "\ea65";
$icon-3d: "\e901";
$icon-accepted-card: "\e902";
$icon-access: "\e903";
$icon-add-money: "\e904";
$icon-add-to-car: "\e905";
$icon-add-doc: "\e906";
$icon-address-book: "\e907";
$icon-alarm: "\e908";
$icon-alert: "\e909";
$icon-align-left-1: "\e90a";
$icon-align-left: "\e90b";
$icon-align-right: "\e90c";
$icon-align: "\e90d";
$icon-amount: "\e90e";
$icon-analytics: "\e90f";
$icon-arrow-angle-double-down: "\e910";
$icon-arrow-angle-double-left: "\e911";
$icon-arrow-angle-double-right: "\e912";
$icon-arrow-angle-double-up: "\e913";
$icon-arrow-diagonal: "\e914";
$icon-arrow-down: "\e915";
$icon-arrow-left: "\e916";
$icon-arrow-long-down: "\e917";
$icon-arrow-long-left: "\e918";
$icon-arrow-long-right: "\e919";
$icon-arrow-long-up: "\e91a";
$icon-arrow-right: "\e91b";
$icon-arrow-up: "\e91c";
$icon-arrow2-down: "\e91d";
$icon-arrow2-left: "\e91e";
$icon-arrow2-right: "\e91f";
$icon-arrow2-up: "\e920";
$icon-exchange: "\e971";
$icon-assistant: "\e921";
$icon-tabot: "\ea6a";
$icon-assistence: "\e922";
$icon-ATM: "\e923";
$icon-bank-commitments: "\e924";
$icon-barcode-scan: "\e925";
$icon-barcode: "\e926";
$icon-basket: "\e927";
$icon-benefits: "\e928";
$icon-bicycle: "\e929";
$icon-bill: "\e92a";
$icon-birthday-cake: "\e92b";
$icon-block: "\e92c";
$icon-book: "\e92d";
$icon-bookmark: "\e92e";
$icon-building: "\e92f";
$icon-car: "\e93f";
$icon-bus: "\e930";
$icon-calculator: "\e931";
$icon-calendar-check: "\e932";
$icon-calendar-day: "\e933";
$icon-calendar-exclamation: "\e934";
$icon-calendar-minus: "\e935";
$icon-calendar-plus: "\e936";
$icon-calendar-premium: "\e937";
$icon-calendar-times: "\e938";
$icon-calendar: "\e939";
$icon-calendar-edit: "\e93a";
$icon-month: "\e9bf";
$icon-call-help: "\e93b";
$icon-camera: "\e93c";
$icon-capital: "\e93d";
$icon-car-plate: "\e93e";
$icon-card: "\e940";
$icon-cards: "\e941";
$icon-carousel: "\e942";
$icon-cash-contribution: "\e943";
$icon-cash-payment: "\e944";
$icon-cash: "\e945";
$icon-channels: "\e946";
$icon-chart-lines: "\e947";
$icon-performance: "\e9d1";
$icon-chart-pie: "\e948";
$icon-chat-comments: "\e949";
$icon-chat-dots: "\e94a";
$icon-chat: "\e94b";
$icon-checks: "\e94c";
$icon-child: "\e94d";
$icon-cinema: "\e94e";
$icon-clave-dinamica: "\e94f";
$icon-cloud-download: "\e950";
$icon-cloud-upload: "\e951";
$icon-cloud: "\e952";
$icon-cloudy: "\e953";
$icon-coffee: "\e954";
$icon-comment: "\e956";
$icon-construction: "\e958";
$icon-contact-center: "\e959";
$icon-contactless: "\e95a";
$icon-countdown: "\e95b";
$icon-currency-exchange: "\e95c";
$icon-data: "\e95d";
$icon-desktop: "\e95f";
$icon-laptop: "\e9aa";
$icon-smartphone: "\e9fe";
$icon-directions: "\e960";
$icon-document-check: "\e961";
$icon-document-delivery: "\e962";
$icon-document-search: "\e963";
$icon-document: "\e964";
$icon-documents: "\e965";
$icon-door: "\e966";
$icon-download-document: "\e967";
$icon-download-file: "\e968";
$icon-download: "\e969";
$icon-e-card: "\e96a";
$icon-e-prepago: "\e96b";
$icon-edit-document: "\e96c";
$icon-edit: "\e96d";
$icon-modify: "\e9bd";
$icon-education: "\e96e";
$icon-erase: "\e96f";
$icon-error: "\e970";
$icon-expand: "\e972";
$icon-external-link: "\e973";
$icon-extinguisher: "\e974";
$icon-face-angry: "\e975";
$icon-face-grin-wink: "\e976";
$icon-face-grin-wink2: "\e977";
$icon-face-laugh: "\e978";
$icon-face-laughbeam: "\e979";
$icon-face-meh: "\e97a";
$icon-face-sad: "\e97b";
$icon-face-smile: "\e97c";
$icon-faceid: "\e97e";
$icon-file-invoice: "\e97f";
$icon-filter: "\e980";
$icon-filters: "\e981";
$icon-finantial-growth-indicator: "\e982";
$icon-config: "\e957";
$icon-finantial: "\e983";
$icon-find-doc: "\e984";
$icon-fingerprint: "\e985";
$icon-fire: "\e986";
$icon-flag: "\e987";
$icon-flight-cancelled: "\e988";
$icon-flight-late: "\e989";
$icon-flight: "\e98a";
$icon-flypass: "\e98b";
$icon-folder: "\e98c";
$icon-food-burger: "\e98d";
$icon-gift: "\e98e";
$icon-gps: "\e98f";
$icon-grocery: "\e990";
$icon-hand-button: "\e991";
$icon-hand-four-fingers: "\e992";
$icon-hand-index: "\e993";
$icon-hand-three-fingers: "\e994";
$icon-hand-top: "\e995";
$icon-hand-two-fingers: "\e996";
$icon-hand: "\e997";
$icon-health: "\e998";
$icon-heart: "\e999";
$icon-help: "\e99a";
$icon-holding-money: "\e99b";
$icon-home: "\e99c";
$icon-house: "\e99d";
$icon-id-number: "\e99e";
$icon-id: "\e99f";
$icon-idea: "\e9a0";
$icon-image: "\e9a1";
$icon-inbox: "\e9a2";
$icon-info: "\e9a3";
$icon-insert-coin: "\e9a4";
$icon-insurance: "\e9a6";
$icon-international: "\e9a7";
$icon-investment: "\e9a8";
$icon-language: "\e9a9";
$icon-law: "\e9ab";
$icon-like: "\e9ac";
$icon-line-of-people: "\e9ad";
$icon-twitter: "\ea17";
$icon-facebook: "\e97d";
$icon-instagram: "\e9a5";
$icon-linkedin: "\e9ae";
$icon-youtube: "\ea26";
$icon-secondary-menu-horizontal: "\e9ef";
$icon-secondary-menu: "\e9f0";
$icon-list: "\e9af";
$icon-menu: "\e9b9";
$icon-location: "\e9b0";
$icon-lock-open: "\e9b1";
$icon-lock: "\e9b2";
$icon-login: "\e9b3";
$icon-luggage: "\e9b4";
$icon-mail-open: "\e9b5";
$icon-mail: "\e9b6";
$icon-medal: "\e9b7";
$icon-medical-assistance: "\e9b8";
$icon-mixed-service: "\e9bb";
$icon-mobile-payment: "\e9bc";
$icon-money-exchange: "\e9be";
$icon-motorcycle: "\e9c0";
$icon-movie: "\e9c1";
$icon-museum: "\e9c2";
$icon-news: "\e9c3";
$icon-no-card: "\e9c4";
$icon-no-people: "\e9c5";
$icon-no-smoking: "\e9c6";
$icon-notification: "\e9c8";
$icon-office: "\e9c9";
$icon-package: "\e9cb";
$icon-pass: "\e9cc";
$icon-password: "\e9cd";
$icon-pet: "\e9d2";
$icon-phone-contact: "\e9d3";
$icon-phone-money: "\e9d4";
$icon-play: "\e9d5";
$icon-pocket: "\e9d7";
$icon-plus: "\e9d6";
$icon-percentage: "\e9d0";
$icon-minus: "\e9ba";
$icon-ok: "\e9ca";
$icon-password-safe: "\ea82";
$icon-podcast: "\e9d8";
$icon-preferential: "\e9d9";
$icon-premium: "\e9da";
$icon-print: "\e9db";
$icon-product-number: "\e9dc";
$icon-puntos-colombia: "\e9dd";
$icon-QR-add: "\e9de";
$icon-QR-heart: "\e9df";
$icon-QR-scan: "\e9e0";
$icon-QR-send: "\e9e1";
$icon-quit: "\e9e2";
$icon-receipt: "\e9e3";
$icon-refresh: "\e9e4";
$icon-remove: "\e9e5";
$icon-removing-card: "\e9e6";
$icon-restaurant: "\e9e7";
$icon-route: "\e9e8";
$icon-salary: "\e9e9";
$icon-save: "\e9ea";
$icon-savings: "\e9eb";
$icon-search-charts: "\e9ec";
$icon-search-money: "\e9ed";
$icon-search: "\e9ee";
$icon-see-balance: "\e9f2";
$icon-send-money-from: "\e9f3";
$icon-send-money-to: "\e9f4";
$icon-send-money: "\e9f5";
$icon-send-money1: "\e9f6";
$icon-settings: "\e9f7";
$icon-share-external-link: "\e9f8";
$icon-share: "\e9f9";
$icon-ship: "\e9fa";
$icon-shopping: "\e9fb";
$icon-shower: "\e9fc";
$icon-smart-watch: "\e9fd";
$icon-sort: "\e9ff";
$icon-sound-off: "\ea00";
$icon-sound-on: "\ea01";
$icon-spinner: "\ea02";
$icon-stethoscope: "\ea04";
$icon-stock-exchange: "\ea05";
$icon-stocks: "\ea06";
$icon-subway: "\ea08";
$icon-successful: "\ea09";
$icon-sunny: "\ea0a";
$icon-swimming: "\ea0b";
$icon-tag-price: "\ea0c";
$icon-tag-sale: "\ea0d";
$icon-target: "\ea0e";
$icon-taxes: "\ea0f";
$icon-temperature: "\ea10";
$icon-thumbs-down: "\ea11";
$icon-thumbs-up: "\ea12";
$icon-thumbtack: "\ea13";
$icon-time: "\ea14";
$icon-trophy: "\ea15";
$icon-turn: "\ea16";
$icon-umbrella-beach: "\ea18";
$icon-undo-payment: "\ea19";
$icon-unemployment: "\ea1a";
$icon-upload: "\ea1b";
$icon-user: "\ea1c";
$icon-value: "\ea1d";
$icon-variations: "\ea1e";
$icon-ico-view: "\ea1f";
$icon-ico-view-slash: "\e9c7";
$icon-vip: "\ea20";
$icon-wallet: "\ea22";
$icon-window-analytics: "\ea23";
$icon-withdraw-cash: "\ea24";
$icon-wrench: "\ea25";
$icon-zoom-minus: "\ea27";
$icon-zoom-plus: "\ea28";
$icon-ico-document-search: "\e963";
$icon-ico-error: "\e970";
$icon-ico-money-plus: "\e904";
$icon-ico-slider-2: "\e957";